#plant-list{

  h3.section_title {
    margin: 0;
    font-size: 2.2em;
    text-transform: uppercase;
  }

  .main_cont {
    margin: 30px auto;
  }

  .aside {
    width: 240px;
    float: left;
  }

  .content_cont {
    width: 655px;
    float: right;

    ul.plant_list{
      width: 100%;
      list-style-type: none;
      margin: 30px 0 0 0;

      li {
        margin: 0 0 5px 0;
        padding: 10px;

        &:nth-child(odd){
          background: #f0f0f0;
        }

        .header {
          cursor: pointer;

          .num{
            width: 75px;
            float: left;
            padding-right: 20px;
            margin-right: 20px;
            font-size: 1em;
            font-weight: bold;
          }

          .copy{
            width: 535px;
            float: left;

            p{
              margin-bottom: 0;
            }
          }
          .title{
            margin: 0 0 5px 0;
            font-size: 1.1em;
            font-weight: bold;
            color: #000000;
          }
        }

        .details{
          border-top: 1px solid #dcdcdc;
          margin-top: 15px;
          padding-top: 15px;
          display: none;

          ul{
            width: 50%;
            float: left;
            list-style-type: none;
            margin: 0;
            padding: 0;

            li{
              margin: 0 0 10px 0;
              padding: 0;

              .key{
                width: 48%;
                float: left;
                font-weight: bold;
              }

              .value{
                width: 48%;
                float: right;
              }
            }
          }
        }
      }
    }

  }
}