/* BOOTSTRAP FIXES */

form {
  margin: 0;
}
.label:empty {
  display: inline-block;
}
* {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  text-shadow: none;
}

hr {
  margin: 10px 0;
  border-color: #dcdcdc;
}

.container {
  padding: 0;
}

/* BPTW BOOTSTRAP OVERRIDES */
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  /*border: none !important;*/
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

