#blog{
  h1{
    text-transform: uppercase;
  }

  .main_cont{
    margin:30px auto;
  }

  .aside{
    width:240px;
    float:right;

    .posts_list{
      margin-top:0;
    }
  }

  .content_cont{
    width:655px;
    float:left;
  }

  .blog_list ul{
    margin: 0;
    padding:0;
    list-style-type: none;

    li{
      margin: 0 0 10px 0;
      padding:0 0 10px 0;

      .meta{
        font-size: 0.8em;
        margin-bottom: 10px;
      }

      img{
        float:left;
        margin-right:20px;
      }

      .copy{
        width:355px;
        float:left;
      }
    }
  }

  .cats_tags{
    margin:30px 0 0 -30px;

    h4{
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1.2em;
    }
  }

  .cats, .tags{
    width:100px;
    float:left;
    margin-left:30px;

    ul{
      list-style-type: none;
      margin: 0;
      padding: 0;

      li{
        margin:0 0 5px 0;
        padding:0;

        span{
          float:right;
        }
      }
    }
  }



}