$background-gradient-transparency: 0.7;

#home{

  .presswork{
    color: $icon_nav_color_1;
  }

  .sheetmetal{
    color: $icon_nav_color_2;
  }

  .welding{
    color: $icon_nav_color_3;
  }

  .assembly{
    color: $icon_nav_color_4;
  }

  .toolroom{
    color: $icon_nav_color_5;
  }

  .overview{
    color: $icon_nav_color_6;
  }

  .lity-iframe-container{
    video{
      width:100%;
      height:100%;
    }
  }

  .header_wrap{
    background: #f5f5f5;

    .hero-slides, .slick-list, .slick-track, .slick-slide{
      height:100%;
      outline:none;
      
      .hero-slide{
        position: relative;
        height: 500px;
        text-align: center;
        color:white;
        width:100%;
        background: no-repeat center;
        background-size: cover;


        .sub-overlay{
          position: absolute;
          top: 0;
          left: 0;
          width:100%;
          height:100%;
          z-index: 2;

          &.presswork {
            background: transparentize($icon_nav_color_1, $background-gradient-transparency);
          }

          &.sheetmetal {
            background: transparentize($icon_nav_color_2, $background-gradient-transparency);
          }

          &.welding {
            background: transparentize($icon_nav_color_3, $background-gradient-transparency);
          }

          &.assembly {
            background: transparentize($icon_nav_color_4, $background-gradient-transparency);
          }

          &.toolroom {
            background: transparentize($icon_nav_color_5, $background-gradient-transparency);
          }

          &.overview{
            background: transparentize($icon_nav_color_6, $background-gradient-transparency);
          }
        }

        .sub-gradient{
          position: absolute;
          bottom: 0;
          left: 0;
          width:100%;
          height:95%;
          z-index: 1;

          background: rgb(255,255,255);
          background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 90%);
        }

        .sub-content{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0 auto;
          z-index: 3;
          width: 95%;
          max-width: 840px;

          h2{
            font-size:3em;
            font-weight: 700;
            color:white;
            text-transform: uppercase;
          }

          .sub-copy {
            margin: 0 0 20px;
          }
          
          p{
            margin: 0;
            color:white;
            font-size: 1.6em;
            line-height: 34px;
            font-weight: 100;
          }

          // Link Styling
          a{
            font-size: 1.2em;
            font-weight:900;
            text-transform: uppercase;
          }

          img{
            margin: 50px auto 0px auto;
          }

        }

        [data-lity]:hover{
          cursor: pointer;
          color:$color-black;
        }

        .sub-video{
          margin-top:10px;
          span{
            cursor: pointer;
            display: inline-block;
            vertical-align: baseline;
            margin: 0;
            font-size: 1.2em;
            font-weight:700;
            text-transform: uppercase;
            letter-spacing: 2px;
          }

          i{
            font-size: 1.2em;
            position: relative;
            vertical-align: sub;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }


    .copy{
      width: 290px;
      float: left;

      strong{
        text-transform: uppercase;
        font-size: 2.15em;
        line-height: 1.3;
        color: #000000;
        font-weight: normal;
      }

      a{
        color: $color-primary;
        font-size: 1.45em;
        text-transform: uppercase;

        &:hover{
          color: #5a5a5a;
        }
      }
    }

    .media_cont{
      width: 623px;
      float: right;
    }

    .placeholder {
      float: right;
      position: relative;
    }

    .video {
      float: right;
      display: none;
    }

    .play_button {
      width: 95px;
      height: 93px;
      background: url('../images/sprite.png') -0px -77px;
      cursor: pointer;
      position: absolute;
      top: 140px;
      left: 265px;
    }
  }
  .content_cont {
    width: 580px;
    float: left;
    margin: 75px 0;

    h1 {
      text-transform: uppercase;
      font-size: 1.5em;
    }



    p{
      font-size:1.05em;

      &:first-of-type {
        margin: 0px 0 25px;
        line-height: 30px;
      }

      strong{
        font-size:1.2em;
        line-height: 34px;
        font-weight:300;
      }
    }
  }

  .featured_news_cont{
    width:220px;
    float:right;
    margin: 75px 0;

    header h2{
      font-weight:600;
      font-size: 1.4em;
      text-transform: uppercase;
      margin-bottom:25px;
    }

    .news_image{
      width:230px;
      height: 130px;
    }

    .news_title{
      font-weight:600;
      font-size: 1.2em;
    }
  }

  .services {
    ul {
      margin-bottom:-15px;

      li {
        display: inline-block;
        text-align: center;
        outline:none;
        height:135px;
        width:160px;

        img {
          margin: 20px auto 30px auto;
        }

        p {
          color: #999;
          font-size: 1em;
          font-weight: 700;
          text-transform: uppercase;
          margin:0 0 18px 0;
        }

        &.presswork:hover, &.presswork.slick-current{
          border-bottom: 5px solid $icon_nav_color_1;
          margin-bottom: 0px;
          p {
            color: #000;
          }
        }

        &.sheetmetal:hover, &.sheetmetal.slick-current{
          border-bottom: 5px solid $icon_nav_color_2;
          margin-bottom: 0px;
          p {
            color: #000;
          }
        }

        &.welding:hover, &.welding.slick-current{
          border-bottom: 5px solid $icon_nav_color_3;
          margin-bottom: 0px;
          p {
            color: #000;
          }
        }

        &.assembly:hover, &.assembly.slick-current{
          border-bottom: 5px solid $icon_nav_color_4;
          margin-bottom: 0px;
          p {
            color: #000;
          }
        }

        &.toolroom:hover, &.toolroom.slick-current{
          border-bottom: 5px solid $icon_nav_color_5;
          margin-bottom: 0px;
          p {
            color: #000;
          }
        }

        &.overview:hover, &.overview.slick-current{
          border-bottom: 5px solid $icon_nav_color_6;
          margin-bottom: 0px;
          p {
            color: #000;
          }
        }
      }
    }
  }
}
