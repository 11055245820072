/* COLOURS */

$color-black: #000000;
$color-white: #ffffff;
$color-dark: #091D27;
$color-red: #FF2500;

$color-body: $color-white;
$color-header: $color-dark;

$color-neutral: #f2f2f2;
$color-neutral-dark: #8e8e8e;

$color-borders: #d4d4d4;

$color-text: #091D27;
$color-text-light: #C1C6C9;

$color-headings: $color-black;

$color-error: #ff0000;

$color-primary: $color-red;

//$color-secondary: #b4d455;

$color-link: $color-primary;
$color-link-hover: lighten($color-link, 5%);
$color-link-active: $color-link-hover;

/* FONTS */

$font-standard: "museo-sans", Arial, Helvetica, Sans-serif;

$font-size-base: 16px;
$font-size-test: (45/16) * 1em; //45px
$font-size-standard: 1em;
$font-size-tiny: 0.75em; // 12px
$font-size-small: 0.875em; // 14px
$font-size-med: 1.125em; // 18px
$font-size-large: 1.25em; // 20px
$font-size-larger: 1.375em; // 22px
$font-size-ludicrous: 1.4375em; // 23px

$font-size-h1: $font-size-small; // 16px
$font-size-h2: $font-size-h1; // 16px
$font-size-h3: 1.75em; // 28px
$font-size-h4: $font-size-large; // 19px
$font-size-h5: $font-size-standard; // 16px
$font-size-h6: $font-size-standard; // 16px

$font-size-hero-standard: $font-size-larger; // 22px
$font-size-hero-small: 1em; // 16px
$font-size-hero-med: 1.5em; // 24px
$font-size-hero-large: 2.75em; // 46px

$font-size-nav: 1.875em; // 30px

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$headings-font: $font-standard;
$headings-font-weight: $font-weight-bold;
$headings-color: $color-headings;
$headings-line-height: 2;

$line-height-base: 1.55;
$line-height-large: 2;

/* STRUCTURE */

$viewport-width: 100%;
$container-width: 960px;
$container-width-wide: 1085px;

/* EXTERNAL */
// TODO: Adjust dots to match style
$slick-font-path: '../fonts/';
$slick-loader-path: '../images/';
$slick-dot-color-active: $color-primary;
$slick-dot-color: $color-text-light;
$slick-arrow-color: $slick-dot-color-active;
$slick-dot-size: 10px;

$color-footer: $color-neutral-dark;


/* Site Specific */
// icon, gradient and text color for sliders
$icon_nav_color_1:#FAB716;
$icon_nav_color_2:#B81F28;
$icon_nav_color_3:#7B3F91;
$icon_nav_color_4:#EA6D00;
$icon_nav_color_5:#0090D7;
$icon_nav_color_6:#009C5D;

