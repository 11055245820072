/* GENERAL */
div.hr {
  height: 2px;
  color: #787878;
  background: #787878;
  overflow: hidden;
  margin:15px 0;
}
* html div.hr {margin:10px 0;}
div.hr hr {display: none;}

div.hr_dashed {
  margin:0px;
  height: 0px;
  overflow: hidden;
  margin: 15px 0;
  border-bottom: 1px dashed #787878;
  position: relative;
}
* html div.hr_dashed {margin:10px 0;}
div.hr_dashed hr {display: none;}

div.hr_thick {
  height: 5px;
  color: #f0f0f0;
  background: #f0f0f0;
  border-top: 1px solid #dcdcdc;
  overflow: hidden;
  margin:15px 0;
}
* html div.hr_thick {margin:10px 0;}
div.hr_thick hr {display: none;}