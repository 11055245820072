// Clearfix
.clearfix:before,
.clearfix:after {
	content: "";
	display: table;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	zoom: 1;
}

// Clearfix for MQs
@mixin clearfix {
	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}

	& {
		zoom: 1;
	}
}


// Remove selectability
.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

// Remove focus styles
.nofocus {
	&:focus {
		outline-style: none;
		box-shadow: none;
	}
}

// Default list styles
.list-default {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin: 0;
		padding: 0;
	}
}

// Reusable, zero-boilerplate background tinting
@mixin background-tint($color, $fraction) {
	&, > * {
		// Recreate the stacking context for child elements
		position: relative;
	}

	&:before {
		content: " ";
		width: 100%;
		height: 100%;
		display: inline-block;
		position: absolute;
		left: 0;
		top: 0;
		background-color: $color;
		opacity: $fraction;
	}
}

@mixin icon-fixed-size($factor) {
	$size: $font-size-base * $factor;

	height: $size;
	width: $size;
	font-size: $size;
}

@mixin background-gradient($gradient) {
	&, > * {
		// Recreate the stacking context for child elements
		position: relative;
	}

	&:before {
		content: " ";
		width: 100%;
		height: 100%;
		display: inline-block;
		position: absolute;
		left: 0;
		top: 0;
		background-image: $gradient;
	}
}

@mixin icon-fixed-size($factor) {
	$size: $font-size-base * $factor;

	height: $size;
	width: $size;
	font-size: $size;
}