#footer{
  background: #f5f5f5;
  border-top: 1px solid #dcdcdc;
  padding-bottom: 20px;

  .contact{
    border-bottom: 1px solid #dcdcdc;
    padding: 20px 0;
    margin-bottom: 10px;
    font-size:0.8em;

    .telephone, .email, .or, .link{
      margin-right: 50px;
      float: left;
      font-size: 1.6em;
    }

    .telephone a, .email a{
      color:#000000;
      font-weight:700;
    }

    .link a{
      color: $color-primary;
      font-weight:700;
    }
  }


  ul{
    margin: 5px 0 0 0;
    padding: 0;
    list-style-type: none;

    li{
      margin: 0 15px 0 0;
      padding: 0 15px 0 0;
      display: inline-block;
      border-right: 1px solid #c8c8c8;
      line-height: 1;
      font-size: 0.95em;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;

      a{
        color: #5a5a5a;
      }
    }

    :last-child{
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }
  }

  .postal-address{
    display: block;
    width:480px;
    line-height: 20px;
    text-align: left;
    margin: 10px 0 0;
    p{
      margin-top:8px;
    }
  }
}