#about{
  h3.section_title {
    margin: 0;
    font-size: 2.2em;
    text-transform: uppercase;
  }
  .main_cont {
    margin: 30px auto;
  }
  .aside {
    width: 240px;
    float: left;
  }
  .content_cont {
    width: 655px;
    float: right;
  }

  ul.certifications{
    margin: 30px 0 0 0;
    padding: 0;
    list-style-type: none;

    li{
      margin: 0 0 10px 0;
      padding: 0;

      img{
        margin-right: 20px;
        float: left;
      }

      .copy{
        width: 510px;
        float: right;
      }
    }
  }
}