#case-studies, #case-study {
  h3.section_title {
    margin: 0;
    font-size: 2.2em;
    text-transform: uppercase;
  }

  .main_cont {
    margin: 30px auto;
  }

  .aside {
    width: 240px;
    float: left;
  }

  .content_cont {
    width: 655px;
    float: right;
  }

  ul.case_studies_list {
    list-style-type: none;
    margin: 30px 0 0 -21px;
    padding: 0;

    li {
      width: 148px;
      margin: 0 0 30px 21px;
      padding: 0;
      float: left;
      font-size: 1.2em;
      text-align: center;

      img {
        margin-bottom: 5px;
      }

      a {
        color: #5a5a5a;
      }

      &:hover a{
        color: $color-primary;
      }
    }
  }
}

#case-study .page_slider {
  height: 320px;
}