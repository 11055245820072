
/* LINKS */
a {
  color: $color-primary;
}
a:active{
  background-color: transparent; /* IE10 fix */
}
a:active, a:focus, a {
  text-decoration: none;
}
a:hover {
  color: #000000;
  text-decoration: none;
}
a:active, a:focus  {
  border: 0 none;
  outline: 0 none;
}

/* BUTTONS GENERAL */
.button {
  text-align: center;
  font-size: 1em;
  display: block;
  border: 1px solid #dcdcdc;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
  background: #ededed; /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VkZWRlZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjN2M3YzciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,  #ededed 0%, #c7c7c7 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ededed), color-stop(100%,#c7c7c7)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #ededed 0%,#c7c7c7 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #ededed 0%,#c7c7c7 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #ededed 0%,#c7c7c7 100%); /* IE10+ */
  background: linear-gradient(to bottom,  #ededed 0%,#c7c7c7 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#c7c7c7',GradientType=0 ); /* IE6-8 */
}
.button:focus,
.button:active,
.button:hover {
  text-decoration: none;
  background: #f2f2f2; /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YyZjJmMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNkOGQ4ZDgiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,  #f2f2f2 0%, #d8d8d8 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f2f2f2), color-stop(100%,#d8d8d8)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #f2f2f2 0%,#d8d8d8 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #f2f2f2 0%,#d8d8d8 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #f2f2f2 0%,#d8d8d8 100%); /* IE10+ */
  background: linear-gradient(to bottom,  #f2f2f2 0%,#d8d8d8 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#d8d8d8',GradientType=0 ); /* IE6-8 */
}
.button:active {
  border: 1px solid white;
}

/* SOCIAL ICONS */
.social_actions .social_button {
  float: left;
  margin-left: 10px;
}

/* COOKIE POLICY */
.cookie_policy_pop {
  width: 100%;
  padding: 0;
  background: #f5f5f5;
  color: #6D6E71;
  position: fixed;
  bottom: 0px;
  z-index: 1001;

  .button {
    display: block;
    width: 70px;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    float: right;
    cursor: pointer;
  }

  .container {
    padding: 10px 0;
  }

  p {
    float: left;
    width: 850px;
    margin: 0;
  }
}

/* PAGINATION */
.pagination {
  width: 100%;
  margin: 0;
  text-align: center;

  .pag_num {
    margin: 0 8px 0 0;
    padding: 0 8px 0 0;
    border-right: 1px solid #787878;
    font-weight: bold;
    font-size: 0.9em;
  }
}
// ??? incomplete at src.
//.pagination .pag_num.current {
//  color: ;
//}