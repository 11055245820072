#services {
  h3.section_title{
    margin: 0;
    font-size: 2.2em;
    text-transform: uppercase;
  }

  .main_cont{
    margin: 30px auto;
  }

  .aside{
    width: 240px;
    float: left;
  }

  .content_cont{
    width: 655px;
    float: right;
  }
}

