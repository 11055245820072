#contact{
  .main_cont{
    margin: 30px auto;

  }

  .details{
    background: #f0f0f0;
    border: 1px solid #dcdcdc;
    padding: 10px;

    h1{
      font-size: 2.5em;
      font-weight: 100;
      padding: 22px 0 22px 0;
      margin: 0;
      color: #000000;
      float: left;
    }

    .info{
      float: right;
      padding: 10px 0 0 0;
      font-size: 1.3em;
      font-weight: bold;

      span, span a{
        color: #000000;
      }

      .col1{
        float: left;
        margin-right: 60px;
      }

      .col2{
        float:right;
      }
    }
  }

  .enquiry{
    h2{
      font-size: 32px;
      font-weight: 100;
      padding: 40px 0 30px 0;
      margin: 0;
      color: #000000;
    }
    .col1{
      width: 450px;
      float: left;
    }
    .col2{
      width: 450px;
      float: right;
    }
  }

  #map_canvas{
    border-top: 1px solid #dcdcdc;
  }

  #location_details{
    position: relative;
    top: 100px;
    width: 910px;
    margin: 0 auto;
    z-index: 99;

    .inner{
      background: #ffffff;
      padding: 15px;
    }

    .col1{
      float: left;
      h2{
        font-size: 32px;
        font-weight: 100;
        padding: 0;
        margin: 0;
        color: #000000;
        line-height: 44px;
      }
    }

    .col2{
      float: right;
      font-size: 15px;

      strong{
        color: #000000;
      }
    }
  }

  .infobox-wrapper{
    display:none;
  }

  .infoBox{
    opacity: 0.75;
    width: 240px;
    position: absolute;
    visibility: visible;
    left: 492.412109375px;
    top: 207.5390625px;
    cursor: default;
  }

  #infobox{
    border-radius: 5px;
    margin-top: 18px;
    background: #000;
    color: #FFFFFF;
    padding: 12px 15px;

    h4{
      margin: 0 0 10px 0;
      padding: 0;
      color: #ffffff;
      font-weight: 500;
    }


  }

  #infobox_tri{
    width: 0;
    height: 0;
    border-bottom: 10px solid #000;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: 8px;
    left: 50%;
    margin-left: -10px;
  }
}


.enquiry{
  label{
    margin: 10px 0;
    display: block;
    font-weight: bold;
    font-size: 13px;
  }

  input[type='text'] {
    width: 428px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    margin-bottom: 10px;
  }

  textarea {
    width: 448px;
    height: 145px;
    padding: 10px;
    margin-bottom: 10px;
  }

  label.error {
    color: #8A1F11;
  }

  input.error {
    border: 1px solid #FBC2C4;
  }

  label.no-contact {
    display: inline;
  }

  .subscribe_label {
    width: 280px;
    float: left;
    font-size: 0.9em;
  }

  #subscribe {
    position: relative;
    margin: 0 0 0 5px;
    top: 2px;
  }

  input.button {
    padding: 12px 20px;
    float: right;
    font-weight: bold;
  }
}
