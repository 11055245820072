#blog_story{

  .main_cont{
    margin:30px auto;
  }

  .aside{
    width:240px;
    float:right;

    .posts_list{
      margin-top:0;
    }
  }

  .content_cont{
    width:655px;
    float:left;
  }
  .cats_tags{
    margin: 30px 0 0 -30px;

    h4{
      text-transform: uppercase;
      font-size:1.6em;
    }
  }

  .cats, .tags{
    width: 100px;
    float: left;
    margin-left: 30px;

    ul{
      list-style-type: none;
      margin:0;
      padding: 0;

      li{
        margin: 0 0 5px 0;
        padding:0;

        span{
          float:right;
        }
      }
    }
  }

  .blog_img{
    margin-bottom:20px
  }

  .meta{
    font-size: 0.8em;
    margin-bottom: 20px;
  }

  .social_actions{
    margin-top:30px;

    p{
      float:left;
      margin:0;
    }

    .social_like_cont{
      float:right;
    }
  }

  .hr{
    margin: 5px 0 10px 0;
  }

  .add_this{
    float:left;
  }

  .share_text{
    float:right;
    font-size: 0.9em;
  }


  .page_slider{
    width:655px;
    height:375px;
  }
}

#blog_story .blog_img {
  margin-bottom: 20px;
}
#blog_story .meta {
  font-size: 0.8em;
  margin-bottom: 20px;
}
#blog_story .social_actions {
  margin-top: 30px;
}
#blog_story .social_actions p {
  float: left;
  margin: 0;
}
#blog_story .social_actions .social_like_cont {
  float: right;
}
#blog_story .hr {
  margin: 5px 0 10px 0;
}
#blog_story .add_this {
  float: left;
}
#blog_story .share_text {
  float: right;
  font-size: 0.9em;
}