h1, h2, h3, h4, h5 {
  padding: 0;
  color: #000000;
  line-height: 1.2;
  margin-top: 0;
  font-family: $font-standard;
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
  font-weight:700;
}

h2 {
  font-size: 1.6em;
}

h3 {
  font-size: 1.4em;
  margin: 20px 0 10px 0;
}
