#parts-examples{
  .main_cont {
    margin: 30px auto;
  }

  .aside {
    width: 240px;
    float: left;
  }

  h3.section_title {
    margin: 0;
    font-size: 2.2em;
    text-transform: uppercase;
  }

  .content_cont {
    width: 655px;
    float: right;
  }

  ul.parts {
    list-style-type: none;
    margin: 30px 0 0 -12px;
    padding: 0;

    li {
      width: 209px;
      height: 167px;
      float: left;
      margin: 0 0 10px 13px;
      padding: 0;
      position: relative;
      overflow: hidden;

      h3.title {
        color: #ffffff;
        font-size: 2em;
      }

      p.zoom {
        color: $color-primary;
      }

      .overlay {
        width: 209px;
        height: 167px;
        display: none;
        padding: 10px;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        background: url('../images/overlay_bg.png');
      }

      &:hover .overlay {
        display: block;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}