body {
  background: #ffffff;
  font-size: 13px;
  font-family: $font-standard;
  color: #5a5a5a;
  line-height: 1.8;
  min-width: 940px;
}
.container {
  width: 940px;
  margin: 0 auto;
}