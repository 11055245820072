/* PAGE SLIDER */

.page_slider {
  width: 655px;
  height: 285px;
  margin-bottom: 35px;
  position: relative;

  ul.slides{
    list-style: none;
    margin: 0;
    padding: 0;

    li{
      margin: 0;
      padding: 0;
    }
  }

  .flex-direction-nav {
    display: none;
  }

  .flex-control-nav {
    z-index: 99;
    bottom: 10px;

    li {
      margin: 0 3px;

      a {
        width: 9px;
        height: 9px;
        background: #f0f0f0;
        border-radius: 9px !important;
        -webkit-border-radius: 9px !important;
        -moz-border-radius: 9px !important;

        &:hover, &.flex-active{
          background: $color-primary;
        }
      }
    }
  }

}


/* PAGE NAV SUBNAV */
aside{
  .page_nav{
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;

    ul{
      list-style-type: none;
      margin: 5px 0;
      padding: 0;

      li{
        margin: 0;
        padding: 9px 0;
        border-bottom: 1px dotted #c8c8c8;
        font-size: 1.2em;
        font-weight:600;
        line-height: 1;

        a{
          color:#5a5a5a;

          &:hover{
            color: #1e1e1e;
          }
        }

        &:last-child{
          border-bottom: none;
        }
        
        &.active{
          .chevron{
            width: 14px;
            height: 21px;
            background: url('../images/sprite.png') -218px -139px;
            float: right;
          }

          ul.subs{
            display:block;
            list-style-type: none;
            margin: 10px 0 0 0;
            padding: 0;
            border-top: 1px dotted #c8c8c8;
          }

          a{
            color:red;
          }
        }

        ul.subs{
          display:none;
          list-style-type: none;
          margin: 10px 0 0 0;
          padding: 0;
          border-top: 1px dotted #c8c8c8;
          li{
            margin: 0 0 0 20px;
            padding: 11px 0 9px 0;
            border-bottom: 1px dotted #c8c8c8;
            font-size: 0.9em;
            line-height: 1;

            a{
              color: #5a5a5a;
            }

            &.active{
              a{
                color:red;
              }
            }

            &:last-child{
              border-bottom: none;
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.posts_list {
  margin-top: 40px;
  h2 {
    background: #f0f0f0;
    margin: 0;
    padding: 15px 15px 0 15px;
    text-transform: uppercase;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 0 10px 0;
      padding: 15px;
      background: #f0f0f0;

      img {
        margin-bottom: 15px;
      }

      a {
        font-weight: bold;
      }

      .meta {
        font-size: 0.8em;
        margin-top: 10px;
      }
    }
  }
}
