#header {
  margin: 15px auto 20px auto;

  .nav_cont{
    width:420px;
    float:right;

    ul {
      margin: 0 0 20px 0;
      padding: 0;
      list-style-type: none;
      line-height: 1;

      li {
        margin: 0 32px 0 0;
        padding: 0 32px 0 0;
        display: inline-block;
        border-right: 1px solid #787878;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;

        a {
          color: #787878;

        }
      }

      .active a, a:hover {
        color: #000000 !important;
      }

      :last-child {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
      }
    }

    .tel{
      float: left;
      font-size: 1.6em;
      line-height: 1;
      margin: 28px 0 0 0;


      a{
        color:#000000;
        font-weight:600;
        :hover{
          color: $color-primary;
        }
      }
    }

    .language{
      float:right;
      margin-top:24px;
    }
  }

  #logo{
    width: 430px;
    height: 61px;
    display: block;
    float: left;
    background: url('../images/sprite.png') -0px -0px;
    text-indent: -9999px;
    margin-top: 5px;
  }
}

#primary_nav{
  background:#E6E6E6;
  height:50px;
  ul{
    width: 940px;
    margin: 10px auto 0 auto;
    padding: 15px 0;
    list-style-type: none;
    text-align: center;

    li{
      margin: 0 20px 0 0;
      padding: 0 20px 0 0;
      display: inline-block;
      border-right: 1px solid rgba(50, 50, 50, 0.1);
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      line-height: 1;


      a{
        color: #5a5a5a;
        font-size: 1.1em;
        text-transform: uppercase;
        font-weight:700;
      }
    }

    :last-child{
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }

    .active a, a:hover{
      color: $color-primary;
    }
  }
}

